<i18n>
{
  "de": {
    "targetsTitle": "Ziele",
    "yearTitle": "Jahr",
    "co2targetTitle": "Ziel GHG-Emissionen",
    "peTargetTitle": "Ziel Primärenergie",
    "noTargets": "Dieses Portfolio enthält keine Ziele",
    "addButtonLabel": "Neues Ziel hinzufügen"
  }
}
</i18n>

<template>
  <div class="targets-section">
    <detail-list v-if="hasTargets || target">
      <template #header>
        <span>{{ $t('yearTitle') }}</span>
        <span class="align-right">{{ $t('co2targetTitle') }}</span>
        <span class="align-right">{{ $t('peTargetTitle') }}</span>
        <span></span>
      </template>
      <edit-target-row :allow-edit="portfolio.permissions.EDIT_TARGETS" v-for="target in targets" :key="target.id" v-model="target.item" :other-targets="otherTargets(target.item)" />
      <edit-target-row v-if="target" v-model="target" :other-targets="allTargets" :edit="true" />
    </detail-list>
    <p v-else>{{ $t('noTargets') }}</p>
    <button-wrapper>
      <button class="button" v-if="!target && portfolio.permissions.EDIT_TARGETS" @click.prevent="add">{{ $t('addButtonLabel')}}</button>
    </button-wrapper>
  </div>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import EditTargetRow from './EditTargetRow'
import DetailList from '@/components/shared/lists/DetailList'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      targets: null,
      target: null
    }
  },
  computed: {
    hasTargets () {
      return Boolean(this.targets) && this.targets.length > 0
    },
    allTargets () {
      return (this.targets || []).map(({ item }) => item)
    }
  },
  watch: {
    value (value) {
      this.updateTargets(value)
    }
  },
  methods: {
    updateTargets (targets) {
      this.targets = (targets || [])
        .map(item => ({ item }))
        .sort((a, b) => a.item.year - b.item.year)
    },
    otherTargets (target) {
      return this.allTargets.filter(t => t.id !== target.id)
    },
    add () {
      const year = this.hasTargets ? this.allTargets[this.allTargets.length - 1].year + 10 : new Date().getFullYear() + 10
      this.target = {
        portfolio_id: this.portfolio.id,
        year,
        co2total: 20,
        pe_total: 130
      }
    }
  },
  created () {
    this.updateTargets(this.value)
  },
  components: {
    ButtonWrapper,
    EditTargetRow,
    DetailList
  }
}
</script>

<style>
.targets-section .align-right {
  text-align: right;
}

.targets-section .detail-list {
  margin-bottom: var(--spacing-m);
}
</style>
