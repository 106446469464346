<i18n>
{
  "de": {
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "deleteConfirmation": "Wollen Sie dieses Ziel wirklich löschen?",
    "duplicateYearErrorMessage": "Jahr bereits vergeben"
  }
}
</i18n>

<template>
  <li class="target-row">
    <form @keydown.esc="onCancel">
      <div>
        <numeric-input noFormatting int v-model="year" :min="minYear" :max="maxYear" :invalid-values="otherYears" :invalid-values-error-message="$t('duplicateYearErrorMessage')" :edit="isEditing" />
      </div>
      <div class="align-right">
        <numeric-input optional v-model="co2total" :edit="isEditing" :units="'kg/m²'" />
      </div>
      <div class="align-right">
        <numeric-input optional v-model="peTotal" :edit="isEditing" :units="'kWh/m²'" />
      </div>
      <button-wrapper v-if="isEditing">
        <button class="button" type="submit" :disabled="submitDisabled" @click.prevent="onSave">{{ $t('saveButtonLabel') }}</button>
        <a class="button" href="#" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</a>
      </button-wrapper>
      <list-button-wrapper v-else>
        <list-edit-button v-if="allowEdit" @click="onEdit" />
        <list-delete-button v-if="allowEdit" @click="onDelete" />
      </list-button-wrapper>
    </form>
  </li>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'

import NumericInput from '@/components/shared/forms/NumericInput'

import { mapActions } from 'vuex'

export default {
  props: {
    allowEdit: Boolean,
    value: Object,
    otherTargets: Array,
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      year: null,
      co2total: null,
      peTotal: null,
      minYear: 2000,
      maxYear: 2100,
      editing: false
    }
  },
  computed: {
    isEditing () {
      return this.edit || this.editing
    },
    isCreate () {
      return Boolean(this.value && !this.value.id)
    },
    submitDisabled () {
      return this.year === null || this.year < this.minYear || this.year > this.maxYear ||
        this.otherYears.includes(this.year) ||
        this.co2total < 0 ||
        this.peTotal < 0
    },
    otherYears () {
      return (this.otherTargets || []).map(t => t.year)
    }
  },
  watch: {
    value (value) {
      this.updateRow(value)
    }
  },
  methods: {
    ...mapActions({
      addTarget: 'portfolio/addTarget',
      updateTarget: 'portfolio/updateTarget',
      deleteTarget: 'portfolio/deleteTarget'
    }),
    updateRow (row) {
      const {
        year,
        co2total,
        pe_total: peTotal
      } = row || {}

      this.year = year
      this.co2total = co2total
      this.peTotal = peTotal
    },
    onSave () {
      const {
        year,
        co2total,
        peTotal
      } = this
      const {
        portfolio_id: portfolioId
      } = this.value || {}

      const target = {
        portfolio_id: portfolioId,
        year,
        co2total,
        pe_total: peTotal
      }
      this.editing = false
      if (this.isCreate) {
        this.addTarget(target)
        this.$emit('input', null)
      } else {
        this.updateTarget({ id: this.value.id, target: target })
      }
    },
    onCancel () {
      this.editing = false
      this.updateRow(this.value)
      if (this.isCreate) {
        this.$emit('input', null)
      }
    },
    onEdit () {
      this.editing = true
    },
    onDelete () {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        const {
          id,
          portfolio_id: portfolioId
        } = this.value || {}

        const target = {
          id,
          portfolio_id: portfolioId
        }
        this.editing = false
        this.deleteTarget(target)
      }
    }
  },
  created () {
    this.updateRow(this.value)
  },
  components: {
    ButtonWrapper,
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    NumericInput
  }
}
</script>

<style>
.target-row > form {
  display: contents;
}

.target-row .align-right {
  text-align: right;
}
</style>
