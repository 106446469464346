<i18n>
{
  "de": {
    "pageTitle": "Reduktionsziele",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <button-wrapper>
        <router-link class="button" :to="{ name: 'settings', params: { portfolio_id: portfolio.id }}">{{ $t('returnButton') }}</router-link>
      </button-wrapper>
      <edit-targets :portfolio="portfolio" :value="portfolio.targets" v-if="portfolio.targets" class="targets-list" />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import EditTargets from '@/components/settings/EditTargets'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  components: {
    ButtonWrapper,
    MainLayout,
    EditTargets
  }
}
</script>

<style>
.targets-page .targets-list {
    max-width: 800px;
    margin-top: var(--spacing-m);
}
</style>
